export const VIP_COLOR = {
  vip1: "vip1-color",
  vip2: "vip2-color",
  vip3: "vip3-color",
};

export const VIP_DAYS = {
  vip1: 30,
  vip2: 45,
  vip3: 60,
};

export const VIP_TEST_COUNT = {
  vip1: 1000,
  vip2: 1500,
  vip3: 2000,
};

export const VIP_ACCOUNT_STATUS_IN_PROGRESS = 0;
export const VIP_ACCOUNT_STATUS_FINISHED_BY_COUNT = 1;
export const VIP_ACCOUNT_STATUS_BY_DAYS = 2;
export const VIP_ACCOUNT_STATUS_CLOSED = 3;
