const state = {
  isShowLoader: false,
};

const getters = {
  isLoader: state => state.isShowLoader,
};

const mutations = {
  setLoader: (state, val) => (state.isShowLoader = val),
};

const actions = {
  setLoader({ commit }, value) {
    commit("setLoader", value);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
