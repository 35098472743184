import { textFormatter } from "./text-formatter";

export function modifyReportForDisplay(report) {
  return {
    ...report,
    cost: (report.cost / 100).toFixed(3),
    // create new formatted fields in order to save original symbols quantity
    text_sent_formatted: textFormatter(report.text_sent),
    text_delivered_formatted: textFormatter(report.text_delivered),
    text_delivered_decorated_formatted: textFormatter(
      report.text_delivered_decorated
    ),
    sender_sent_formatted: textFormatter(report.sender_sent),
    sender_delivered_formatted: textFormatter(report.sender_delivered),
    sender_delivered_decorated_formatted: textFormatter(
      report.sender_delivered_decorated
    ),
  };
}
