import axios from "axios";
import { downloadFile } from "../../helper/helper";
const state = {
  reportFetching: false,
  reports1CAllList: [],
};

const getters = {
  isReportFetching(state) {
    return state.reportFetching;
  },
  reports1CAllList(state) {
    return state.reports1CAllList;
  },
};

const mutations = {
  setFetchingReport(state, value) {
    state.reportFetching = value;
  },
  set1CReports(state, data) {
    state.reports1CAllList = data;
  },
};

const actions = {
  downloadReportProxy(context, data) {
    context.commit("setFetchingReport", true);
    return axios
      .post("/reports/proxy/download", data, {
        responseType: "blob",
      })
      .then(res => {
        if (res.data.status === "processing" || res.status === 204) {
          localStorage.setItem("uuidReportProxy", data.report_uuid);
          setTimeout(() => {
            context.dispatch("downloadReportProxy", {
              report_uuid: localStorage.getItem("uuidReportProxy"),
            });
          }, 4000);
          return res.data;
        }
        downloadFile(res);
        //loader state in header
        context.commit("setFetchingReport", false);
        localStorage.removeItem("uuidReportProxy");
        return res.data;
      })
      .catch(error => {
        //loader state in header
        context.commit("setFetchingReport", false);
        localStorage.removeItem("uuidReportProxy");
        return error.data;
      });
  },
  download1CReport(context, data) {
    context.commit("setFetchingReport", true);
    return axios
      .get(
        `export/generate?dateStart=${data.dateStart}&dateEnd=${data.dateEnd}`
      )
      .then(response => {
        if (response.data.status === "processing" || response.status === 204) {
          localStorage.setItem("dateReport1C", JSON.stringify(data));
          setTimeout(() => {
            context.dispatch("download1CReport", data);
          }, 4000);
          return response.data;
        }
        //loader state in header
        context.commit("setFetchingReport", false);
        //reload all 1c reports
        context.dispatch("get1CReportsAllList");

        localStorage.removeItem("dateReport1C");
        downloadFile(response);

        return response.data;
      })
      .catch(error => {
        //loader state in header
        context.commit("setFetchingReport", false);
        localStorage.removeItem("dateReport1C");
        return error.data;
      });
  },
  get1CReportsAllList(context) {
    return axios
      .get("export/all")
      .then(response => {
        context.commit("set1CReports", response.data.data);
        return response.data.data;
      })
      .catch(error => {
        return error.response;
      });
  },
  downloadReport(context, uuid) {
    context.commit("setFetchingReport", true);
    return axios
      .post("reports/download", uuid, {
        responseType: "blob",
      })
      .then(response => {
        if (response.status === 204) {
          localStorage.setItem("report_uuid", uuid.report_uuid);
          setTimeout(() => {
            context.dispatch("downloadReport", uuid);
          }, 5000);
          return response.data;
        }
        context.commit("setFetchingReport", false);
        localStorage.removeItem("report_uuid");

        downloadFile(response);
        return response.data;
      })
      .catch(error => {
        context.commit("setFetchingReport", false);
        localStorage.removeItem("report_uuid");
        return error.response;
      });
  },
  downloadCustomReport(context, uuid) {
    context.commit("setFetchingReport", true);
    return axios
      .post("/reports/custom/download", uuid, {
        responseType: "blob",
      })
      .then(response => {
        if (response.status === 204) {
          localStorage.setItem("report_uuid", uuid.report_uuid);
          setTimeout(() => {
            context.dispatch("downloadCustomReport", uuid);
          }, 5000);
          return response.data;
        }
        context.commit("setFetchingReport", false);
        localStorage.removeItem("report_uuid");

        downloadFile(response);
        return response.data;
      })
      .catch(error => {
        context.commit("setFetchingReport", false);
        localStorage.removeItem("report_uuid");
        return error.response;
      });
  },
  downloadMoReport(context, uuid) {
    context.commit("setFetchingReport", true);
    return axios
      .post("/reports/mo/download", uuid, {
        responseType: "blob",
      })
      .then(response => {
        if (response.status === 204) {
          localStorage.setItem("report_uuid", uuid.report_uuid);
          setTimeout(() => {
            context.dispatch("downloadMoReport", uuid);
          }, 5000);
          return response.data;
        }
        context.commit("setFetchingReport", false);
        localStorage.removeItem("report_uuid");

        downloadFile(response);
        return response.data;
      })
      .catch(error => {
        context.commit("setFetchingReport", false);
        localStorage.removeItem("report_uuid");
        return error.response;
      });
  },
  downloadMassReport(context, uuid) {
    context.commit("setFetchingReport", true);
    return axios
      .post("/reports/autotest/download", uuid, {
        responseType: "blob",
      })
      .then(response => {
        if (response.status === 204) {
          localStorage.setItem("report_uuid", uuid.report_uuid);
          setTimeout(() => {
            context.dispatch("downloadMassReport", uuid);
          }, 5000);
          return response.data;
        }
        context.commit("setFetchingReport", false);
        localStorage.removeItem("report_uuid");

        downloadFile(response);
        return response.data;
      })
      .catch(error => {
        context.commit("setFetchingReport", false);
        localStorage.removeItem("report_uuid");
        return error.response;
      });
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
