<template>
  <header id="header">
    <div class="headerLogo">
      <router-link
        tag="a"
        class="headerLogoLink"
        :to="{ name: 'sms-report' }"
        exact>
        <LogoIcon />
      </router-link>
      <emulator-control />
    </div>
    <div class="additional-info">
      <div class="progress-bar_result" v-if="isReportFetching">
        <span
          ><i class="el-icon-time"></i>Your report is generating, please
          wait</span
        >
        <i class="el-icon-loading loader-mass-test"></i>
      </div>
      <analytics-progress
        v-if="
          analyticsProgress > 0 &&
          analyticsProgress < 100 &&
          $route.name !== 'analytics-tests'
        "
        :progress="analyticsProgress" />
      <el-dropdown
        trigger="click"
        @command="dropdownHandler"
        style="cursor: pointer">
        <span class="el-dropdown-link">
          {{ email }}<i class="el-icon-arrow-down el-icon--right"></i>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="my-profile">My profile</el-dropdown-item>
          <el-dropdown-item command="logout">Logout</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
      <router-link
        tag="a"
        class="profile-link"
        :to="{ name: 'my-profile' }"></router-link>
    </div>
  </header>
</template>

<script>
import { mapGetters } from "vuex";
import LogoIcon from "@/components/icons/LogoIcon.vue";
import EmulatorControl from "./components/emulator-control.vue";
import AnalyticsProgress from "./components/analytics-progress.vue";

export default {
  components: { AnalyticsProgress, EmulatorControl, LogoIcon },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      email: "getSelfEmail",
      isReportFetching: "isReportFetching",
      analyticsProgress: "analytics/getProgress",
    }),
  },
  watch: {
    analyticsProgress(value) {
      if (value === 100 && this.$route.name !== "analytics-tests") {
        this.$successMessage("Analytics generation finished");
      }
    },
  },
  methods: {
    dropdownHandler(command) {
      if (command === "logout") {
        this.$store.dispatch("signout");
        this.$router.push("/");
        return;
      }
      this.$router.push({ name: command });
    },
  },
};
</script>
