export const superadmin = 1;
export const admin = 2;
export const support = 3;
export const commercial = 4;
export const financial = 5;
import store from "../store/index";
import router from "./index";
const ROUTE_PERMISSION = {
  invoices: [superadmin, admin, financial],
  "sms-report": [superadmin, admin, support],
  "sms-report-id": [superadmin, admin, support],
  "proxy-tests": [superadmin, admin, support],
  "proxy-test-id": [superadmin, admin, support],
  "custom-number-reports": [superadmin, admin, support],
  "mo-reports": [superadmin, admin, support],
  "mo-report-id": [superadmin, admin, support],
  "admin-list": [superadmin],
  "admin-profile": [superadmin],
  binds: [superadmin, admin, support],
  vendors: [superadmin, admin, support],
  "binds-logs": [superadmin, admin, support],
  "api-constructors": [superadmin, admin, support],
  "provider-list": [superadmin, admin, support],
  "mo-networks": [superadmin, admin, support],
  "mo-numbers": [superadmin, admin, support],
  "nodes-list": [superadmin, admin, support],
  "text-templates": [superadmin, admin],
  "sms-templates": [superadmin, admin, support],
  notifications: [superadmin, admin, commercial],
};

export default function (route) {
  if (!ROUTE_PERMISSION[route.name]) return; // route has free permission

  const role = store.getters.getRoleID;

  if (!ROUTE_PERMISSION[route.name].includes(+role)) {
    router.push({ name: "page-forbidden" });
  }
}
