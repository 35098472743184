import axios from "axios";
import * as Constants from "@/constants";
import { errorMessage } from "@/helper";
const state = {
  progress: 0,
  lastRequestedFilters: null,
};
const getters = {
  getProgress(state) {
    return state.progress;
  },
  getFilters(state) {
    return state.lastRequestedFilters;
  },
};
const mutations = {
  updateProgress(state, payload) {
    state.progress = payload.progress;
    state.lastRequestedFilters = payload.filters;
  },
  resetProgress() {
    state.progress = 0;
    state.lastRequestedFilters = null;
  },
};
const actions = {
  async checkProgress(ctx) {
    try {
      const {
        data: { data },
      } = await axios.get("/analytics/progress");
      ctx.commit("updateProgress", data);

      if (data.error) {
        errorMessage(data.error);
        ctx.commit("updateProgress", { progress: 0, filters: [] });
      }

      if (data.progress < 100 && data.progress !== 0) {
        setTimeout(
          () => ctx.dispatch("checkProgress"),
          Constants.ANALYTIC_PROGRESS_UPDATE_INTERVAL
        );
      }
    } catch (e) {
      errorMessage(
        e.response?.data?.message || "Error occurred on analytics generation"
      );
    } finally {
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
