<template>
  <div>
    <app-header></app-header>
    <app-left-menu></app-left-menu>
    <app-loader v-if="isLoader" />

    <main v-else>
      <transition name="fade">
        <keep-alive include="user-list">
          <router-view :key="$route.path"></router-view>
        </keep-alive>
      </transition>
    </main>
  </div>
</template>

<script>
import AppHeader from "./../components/header/header.vue";
import AppLeftMenu from "./../components/left_menu/left_menu.vue";
import Loader from "../components/Loader.vue";
import { mapActions } from "vuex";

export default {
  async created() {
    this.reportGeneratingCheck();
    this.setLoader(true);
    await Promise.allSettled([
      this.fetchAllDictionary(),
      this.fetchProfile(),
      this.fetchRegions(),
      this.fetchCurrencies(),
      this.checkAnalyticsProgress(),
    ]);
    this.setLoader(false);
  },

  computed: {
    isLoader() {
      return this.$store.getters.isLoader;
    },
  },
  components: {
    "app-loader": Loader,
    "app-header": AppHeader,
    "app-left-menu": AppLeftMenu,
  },
  methods: {
    ...mapActions({
      fetchCurrencies: "fetchCurrencies",
      fetchProfile: "fetchProfileInfo",
      fetchRegions: "fetchRegions",
      fetchAllDictionary: "fetchAllDictionary",
      setLoader: "setLoader",
      checkAnalyticsProgress: "analytics/checkProgress",
    }),
    reportGeneratingCheck() {
      const uuid = localStorage.getItem("report_uuid"),
        date1CReport = localStorage.getItem("dateReport1C"),
        uuidProxyReport = localStorage.getItem("uuidReportProxy");
      if (uuid) {
        this.$store
          .dispatch("downloadReport", { report_uuid: uuid })
          .then(res => {
            if (res.status === 409) {
              this.$alert("The report is not valid", "Error", {
                confirmButtonText: "OK",
                type: "error",
                center: true,
              });
            }
          });
      }
      if (date1CReport) {
        this.$store
          .dispatch("download1CReport", JSON.parse(date1CReport))
          .then(response => {
            if (response.status === "error")
              this.$alert("The report is not valid", "Error", {
                confirmButtonText: "OK",
                type: "error",
                center: true,
              });
          });
      }
      if (uuidProxyReport) {
        this.$store
          .dispatch("downloadReportProxy", { report_uuid: uuidProxyReport })
          .then(res => {
            if (res.status === 409) {
              this.$alert("The report is not valid", "Error", {
                confirmButtonText: "OK",
                type: "error",
                center: true,
              });
            }
          });
      }
    },
  },
};
</script>
