/* Middleware на проверку авторизации и прав */
import router from "./index";

export default function (route, redirect, next) {
  if (!!localStorage.getItem("token")) {
    next();
  } else {
    router.push({ name: "login-page" });
  }
}
