import Vue from "vue";
import Router from "vue-router";

import LoginPage from "./../pages/login/login.vue";

import AdminTemplate from "./../pages/admin-template.vue";

import requireAuth from "./requireAuth";
import rolePermissions from "./rolePermissions";
import restorePassword from "./restorePassword";
Vue.use(Router);

/* VueRouter */
const router = new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      name: "login-page",
      component: LoginPage,
      meta: { title: "Login" },
    },
    {
      path: "/admin",
      component: AdminTemplate,
      children: [
        { path: "", redirect: { name: "not-found" } },

        { path: "/restore/:hash", beforeEnter: restorePassword },

        {
          path: "sms-report",
          name: "sms-report",
          component: () => import("./../pages/sms-report/sms-report.vue"),
          beforeEnter: requireAuth,
          meta: { title: "SMS Report" },
        },
        {
          path: "sms-report/:id",
          name: "sms-report-id",
          component: () => import("./../pages/sms-report/report.vue"),
          beforeEnter: requireAuth,
          meta: { title: "Report" },
        },
        {
          path: "proxy-tests",
          name: "proxy-tests",
          component: () => import("./../pages/proxy-tests/proxy-tests.vue"),
          beforeEnter: requireAuth,
          meta: { title: "Proxy tests" },
        },
        {
          path: "proxy-tests/:id",
          name: "proxy-test-id",
          component: () => import("./../pages/proxy-tests/test.vue"),
          beforeEnter: requireAuth,
          meta: { title: "Proxy test" },
        },
        {
          path: "custom-number-reports",
          name: "custom-number-reports",
          component: () =>
            import("./../pages/custom-number-report/custom-number-report.vue"),
          beforeEnter: requireAuth,
          meta: { title: "Custom Number Report" },
        },
        {
          path: "mo-reports",
          name: "mo-reports",
          component: () => import("./../pages/mo-reports/mo-reports.vue"),
          beforeEnter: requireAuth,
          meta: { title: "MO reports" },
        },
        {
          path: "mo-reports/:id",
          name: "mo-report-id",
          component: () => import("./../pages/mo-reports/report.vue"),
          beforeEnter: requireAuth,
          meta: { title: "MO Report" },
        },
        {
          path: "auto-reports",
          name: "auto-reports",
          component: () => import("./../pages/mass-reports/mass-reports.vue"),
          beforeEnter: requireAuth,
          meta: { title: "Auto reports" },
        },
        {
          path: "auto-reports/:id",
          name: "auto-report-id",
          component: () => import("./../pages/mass-reports/report.vue"),
          beforeEnter: requireAuth,
          meta: { title: "Auto Report" },
        },
        {
          path: "my-profile",
          name: "my-profile",
          component: () => import("./../pages/my-profile/my-profile.vue"),
          beforeEnter: requireAuth,
          meta: { title: "My profile" },
        },
        {
          path: "my-profile/password",
          name: "reset-password",
          component: () => import("./../pages/my-profile/my-profile.vue"),
          beforeEnter: requireAuth,
          meta: { title: "My profile" },
        },

        {
          path: "admin-list",
          name: "admin-list",
          component: () => import("./../pages/admin-list/admin-list.vue"),
          beforeEnter: requireAuth,
          meta: { title: "Admin list" },
        },
        {
          path: "admin-profile/:id",
          name: "admin-profile",
          component: () => import("./../pages/admin-profile/admin-profile.vue"),
          beforeEnter: requireAuth,
          meta: { title: "Admin profile" },
        },

        {
          path: "provider-list",
          name: "provider-list",
          component: () => import("./../pages/provider-list/provider-list.vue"),
          beforeEnter: requireAuth,
          meta: { title: "Providers" },
        },
        {
          path: "mo-networks",
          name: "mo-networks",
          component: () => import("../pages/mo-networks/mo-networks.vue"),
          beforeEnter: requireAuth,
          meta: { title: "MO networks" },
        },
        {
          path: "mo-numbers",
          name: "mo-numbers",
          component: () => import("../pages/mo-numbers/mo-numbers.vue"),
          beforeEnter: requireAuth,
          meta: { title: "MO numbers" },
        },
        {
          path: "user-list",
          name: "user-list",
          component: () => import("./../pages/user-list/user-list.vue"),
          beforeEnter: requireAuth,
          meta: { title: "User list" },
        },
        {
          path: "feature-requests",
          name: "feature-requests",
          component: () =>
            import("./../pages/feature-requests/feature-requests.vue"),
          beforeEnter: requireAuth,
          meta: { title: "Feature requests" },
        },
        {
          path: "roles",
          name: "roles",
          component: () => import("./../pages/roles/roles.vue"),
          beforeEnter: requireAuth,
          meta: { title: "Roles and actions" },
        },
        {
          path: "user-profile/:id",
          name: "user-profile",
          component: () => import("./../pages/user-profile/user-profile.vue"),
          beforeEnter: requireAuth,
          meta: { title: "User profile" },
        },

        {
          path: "payment-plans",
          name: "payment-plans",
          component: () => import("./../pages/payment-plans/payment-plans.vue"),
          beforeEnter: requireAuth,
          meta: { title: "Payment plans" },
        },
        {
          path: "invoices",
          name: "invoices",
          component: () => import("./../pages/invoices/invoices.vue"),
          beforeEnter: requireAuth,
          meta: { title: "Invoices" },
        },
        {
          path: "binds",
          name: "binds",
          component: () => import("./../pages/binds/binds.vue"),
          beforeEnter: requireAuth,
          meta: { title: "Binds" },
        },
        {
          path: "api-constructors",
          name: "api-constructors",
          component: () =>
            import("./../pages/api-constructors/api-constructors.vue"),
          beforeEnter: requireAuth,
          meta: { title: "Api constructors" },
        },
        {
          path: "binds/:id/logs",
          name: "binds-logs",
          component: () => import("./../pages/binds/logs.vue"),
          beforeEnter: requireAuth,
          meta: { title: "Binds Logs" },
        },
        {
          path: "vendors",
          name: "vendors",
          component: () => import("./../pages/vendors/vendors.vue"),
          beforeEnter: requireAuth,
          meta: { title: "Vendors" },
        },
        {
          path: "nodes-list",
          name: "nodes-list",
          component: () => import("./../pages/nodes-list/nodes-list.vue"),
          beforeEnter: requireAuth,
          meta: { title: "Nodes" },
        },
        {
          path: "text-templates",
          name: "text-templates",
          component: () =>
            import("./../pages/text-templates/text-templates.vue"),
          beforeEnter: requireAuth,
          meta: { title: "Text templates" },
        },
        {
          path: "/text-templates/user/:id",
          name: "text-templates-account",
          component: () =>
            import(
              "./../pages/text-template-account/text-template-account.vue"
            ),
          beforeEnter: requireAuth,
          meta: { title: "Account templates" },
        },
        {
          path: "sms-templates",
          name: "sms-templates",
          component: () => import("./../pages/sms-templates/sms-templates.vue"),
          beforeEnter: requireAuth,
          meta: { title: "SMS templates" },
        },
        {
          path: "notifications",
          name: "notifications",
          component: () => import("./../pages/notifications/notifications.vue"),
          beforeEnter: requireAuth,
          meta: { title: "Notifications" },
        },
        {
          path: "analytics",
          component: () => import("./../pages/analytics/analytics.vue"),
          children: [
            {
              path: "tests",
              name: "analytics-tests",
              component: () =>
                import(
                  "../pages/analytics/views/analytics-tests/analytics-tests.vue"
                ),
              beforeEnter: requireAuth,
              meta: { title: "Analytics tests" },
            },
          ],
        },
        { path: "*", redirect: { name: "not-found" } },
      ],
    },
    {
      path: "/forbidden",
      name: "page-forbidden",
      meta: { title: "Forbidden" },
      component: () => import("./../pages/page-forbidden/page-forbidden.vue"),
    },
    {
      path: "/not-found",
      name: "not-found",
      meta: { title: "Not found" },
      component: () => import("./../pages/page-not-found/page-not-found.vue"),
    },
    { path: "*", redirect: { name: "not-found" } },
  ],
});
function setDocumentTitle(route) {
  document.title = "TLM admin | " + route.meta.title;
}

router.beforeEach((to, from, next) => {
  Vue.prototype.$previousPage = from;
  setDocumentTitle(to);
  rolePermissions(to);
  next();
});
export default router;
