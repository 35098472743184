import moment from "moment";

export const getFilterInterval = (key = "today") => {
  let interval = null;
  switch (key) {
    case "today":
      {
        interval = {
          from: {
            date: moment().format("YYYY-MM-DD"),
          },
          to: {
            date: moment().format("YYYY-MM-DD"),
          },
        };
      }
      break;

    case "week":
      {
        interval = {
          from: {
            date: moment().startOf("week").format("YYYY-MM-DD"),
          },
          to: {
            date: moment().endOf("week").format("YYYY-MM-DD"),
          },
        };
      }
      break;

    case "month":
      {
        interval = {
          from: {
            date: moment().startOf("month").format("YYYY-MM-DD"),
          },
          to: {
            date: moment().endOf("month").format("YYYY-MM-DD"),
          },
        };
      }
      break;

    case "last-month":
      {
        interval = {
          from: {
            date: moment()
              .subtract(1, "month")
              .startOf("month")
              .format("YYYY-MM-DD"),
          },
          to: {
            date: moment()
              .subtract(1, "month")
              .endOf("month")
              .format("YYYY-MM-DD"),
          },
        };
      }
      break;
  }
  return interval;
};
