/* Middleware для обработки запроса на сброс пароля */
import axios from "axios";
import store from "../store";
import router from "./index";

export default function (route, redirect, next) {
  axios
    .post("/auth/login/hash", { hash: route.params.hash })
    .then(function (response) {
      let data_response = response && response.data && response.data.data;

      if (data_response && response.headers.authorization) {
        this.$store.dispatch("signin", {
          ...data_response,
          ...{
            token: response.headers.authorization,
          },
        });

        store.dispatch("restore", response.data.data.hash);

        router.push({ name: "reset-password" });
      } else {
        router.push("/");
      }
    })
    .catch(function (error) {
      router.push("/");
    });
}
