import moment from "moment";
export const getMonthInterval = date => {
  if (date) {
    return {
      from: {
        date: moment(date).startOf("month").format("YYYY-MM-DD"),
      },
      to: {
        date: moment(date)
          .startOf("month")
          .add(1, "month")
          .subtract(1, "day")
          .format("YYYY-MM-DD"),
      },
    };
  }
  return {
    from: {
      date: moment().format("YYYY-MM-DD"),
    },
    to: {
      date: moment().format("YYYY-MM-DD"),
    },
  };
};
