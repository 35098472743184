export const DEFAULT_ANALYTIC_PROVIDER = "mobirich";
export const DEFAULT_ANALYTIC_PROXY_FLAG = 2;
export const DEFAULT_ANALYTIC_PAID_STATUS = 3;
export const DEFAULT_ANALYTIC_FILTER_STATE = {
  provider: DEFAULT_ANALYTIC_PROVIDER,
  countries: [],
  networks: [],
  test_statuses: [],
  start_date: null,
  end_date: null,
  start_date_compare: null,
  end_date_compare: null,
  proxy_flag: DEFAULT_ANALYTIC_PROXY_FLAG,
  paid_status: DEFAULT_ANALYTIC_PAID_STATUS,
  main_criterion: null,
  additional_criteria: [],
  visible: false,
};

export const ANALYTIC_PROGRESS_UPDATE_INTERVAL = 5000;
export const FILTERS_NOT_ALLOW_TO_ASSIGN = [
  "pagination_limit",
  "pagination_page",
  "sorting",
  "max_delta",
  "min_delta",
];
export const FILTERS_NOT_ALLOW_FOR_PRESETS = [
  "pagination_limit",
  "pagination_page",
  "sorting",
  "start_date",
  "end_date",
  "start_date_compare",
  "end_date_compare",
  "max_delta",
  "min_delta",
];
