<template>
  <div class="d-flex align-items-center analytics-progress-wrapper">
    <el-progress :percentage="Math.floor(progress)"></el-progress>
    <i class="el-icon-loading loading-icon"></i>
  </div>
</template>

<script>
export default {
  name: "analytics-progress",
  props: {
    progress: {
      type: Number | undefined | null,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.analytics-progress-wrapper {
  margin-right: 10px;

  ::v-deep {
    .el-progress {
      width: 200px;

      .el-progress-bar {
        padding-right: 40px;
        margin-right: -45px;
      }

      &__text {
        color: #ffffff;
      }
    }
  }

  .loading-icon {
    color: #ffffff;
    font-size: 18px;
  }
}
</style>
