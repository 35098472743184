<template>
  <div class="tests-emulator" v-if="isDev">
    <strong v-if="!profile.isPM">
      <span v-if="form.is_disabled"
        >Using real testing for DEV:
        <span class="info-text color--success"
          >{{ form.tests_count }}/{{ form.tests_limit }}</span
        ></span
      >
      <span v-else
        >Test emulator for DEV:
        <span class="info-text color--success">enabled</span></span
      >
    </strong>
    <div v-else class="d-flex align-items-center">
      <el-switch
        class="state-switcher"
        v-model="form.is_disabled"
        active-color="#f56c6c"
        inactive-color="#409eff"
        active-text="Use real test services"
        inactive-text="Use test emulator">
      </el-switch>
      <el-input-number
        v-if="form.is_disabled"
        style="margin-left: 10px"
        v-model="form.tests_limit"
        size="mini"
        :step="10"></el-input-number>
      <help-tip
        v-if="form.is_disabled"
        :text="`Used tests: ${form.tests_count}`"
        style="margin-left: 5px" />
      <el-button
        type="primary"
        icon="el-icon-success"
        round
        size="mini"
        style="margin-left: 10px"
        :loading="loading"
        @click="saveEmulatorSettings"
        >Save</el-button
      >
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";
import HelpTip from "@/components/help-tip.vue";

export default {
  name: "emulator-control",
  components: { HelpTip },
  mounted() {
    this.fetchEmulatorInfo();
  },
  data() {
    return {
      form: {},
      loading: false,
    };
  },
  computed: {
    ...mapGetters({
      profile: "getProfileInfo",
      isDev: "isDev",
    }),
  },
  methods: {
    async fetchEmulatorInfo() {
      if (!this.isDev) return;
      try {
        const {
          data: { data },
        } = await axios.get("/settings/emulator");
        this.form = data;
      } catch (e) {}
    },
    async saveEmulatorSettings() {
      try {
        if (this.form.is_disabled && this.form.tests_limit <= 0) {
          this.$warningMessage("Please set test limit for real testing");
          return;
        }

        this.loading = true;
        const payload = {
          is_disabled: this.form.is_disabled,
          tests_limit: this.form.tests_limit,
        };
        const {
          data: { data },
        } = await axios.post("/settings/emulator", payload);
        this.form = data;
        this.$successMessage("Test emulator settings saved");
      } catch (e) {
        this.$errorMessage(
          e.response?.data?.message || "Error! Something went wrong"
        );
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.tests-emulator {
  margin-left: 45px;
  color: #ffffff;
  .info-text {
    display: inline-block;
    min-width: 70px;
  }
  .state-switcher {
    ::v-deep {
      .el-switch__label {
        &:not(.is-active) {
          color: #ffffff !important;
        }
        &--right.is-active {
          color: #f56c6c !important;
        }
      }
    }
  }
}
</style>
