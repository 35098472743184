export const NOTIFICATION_EDITOR_CONFIG = {
  height: 400,
  menubar: false,
  statusbar: false,
  plugins: ["autolink lists link image preview", "help"],
  toolbar:
    "undo redo | formatselect | bold italic | image link | \
alignleft alignright alignjustify | \
bullist numlist outdent indent | removeformat | help ",
};

export const NOTIFICATION_GENERAL_TYPE = "General";
export const NOTIFICATION_DRAFT_STATUS = 0;
export const NOTIFICATION_PUBLISH_STATUS = 1;
export const NOTIFICATION_DELETED_STATUS = 2;
